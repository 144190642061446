<template>
  <v-menu
    v-model="fromDateMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        label="Plage de dates"
        prepend-icon="mdi-calendar"
        class="cursor-pointer"
        readonly
        outlined
        :value="dateRangeText"
        hide-details
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dateRange"
      no-title
      range
      first-day-of-week="1"
      @change="closeDatePicker()"
      :min="minDate"
      :max="maxDate"
    >
      <template v-slot:default>
        <v-row dense class="mt-n2">
          <v-col v-for="(preset, i) in presets" :key="i" cols="12">
            <v-btn color="accent" block tile @click="datePreset(preset)"
              >{{ preset.value }} {{ $t(preset.unit) }}</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
  data: () => ({
    fromDateMenu: false,
    minDate: dayjs().subtract(2, "year").format("YYYY-MM-DD"),
    maxDate: dayjs().format("YYYY-MM-DD"),
    presets: [
      { unit: "week", value: "1" },
      { unit: "month", value: "1" },
      { unit: "month", value: "3" },
      { unit: "month", value: "6" },
      { unit: "year", value: "1" },
    ],
  }),
  computed: {
    dateRangeText() {
      return (
        this.$options.filters.formatDate(this.dateRange[0]) +
        " ↔ " +
        this.$options.filters.formatDate(this.dateRange[1])
      );
    },

    dateRange: {
      get: function () {
        return this.$store.state.alerts.alertsDateRange;
      },
      set: function (newValue) {
        if (dayjs(newValue[0]).isAfter(dayjs(newValue[1]))) {
          newValue = newValue.reverse();
        }
        this.$store.state.alerts.alertsDateRange = newValue;
      },
    },
  },
  methods: {
    closeDatePicker() {
      this.$store.dispatch("alerts/processDateRange", this.dateRange);
      this.$emit("onDateUpdate", this.dateRange);
      this.fromDateMenu = false;
    },

    datePreset(preset) {
      const dates = [
        dayjs().subtract(preset.value, preset.unit).format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ];

      this.$emit("onDateUpdate", dates);
      this.$store.dispatch("alerts/processDateRange", dates);

      this.fromDateMenu = false;
    },
  },
};
</script>

<style>
.cursor-pointer input,
.cursor-pointer .v-input__slot {
  cursor: pointer !important;
}
</style>
